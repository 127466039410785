import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { BehaviorSubject, finalize, throwError } from 'rxjs';
import { baseUrl, httpAuthOptions, httpOptions } from '../classes/general';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  onGenerateOtpSubject: any;
  states: any;
  public locationSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  lgas: any;
  lgasSubject: any;

  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService
  ) {}

  getStates() {
    return this._http.get<any>(`${baseUrl}/api/location/GetStates`).subscribe({
      next: (res) => {
        // console.log(res);
        let result = this._encryptDecrypt.decrypt(res.data);
        this.locationSubject.next(result);
      },
      error: (err) => {
        // console.log(err.error);
      },
    });
  }

  getLGAs(id: any) {
    return this._http
      .get<any>(`${baseUrl}/api/location/GetLGAs/${id}`)
      .subscribe({
        next: (res) => {
          let result = this._encryptDecrypt.decrypt(res.data);
          this.lgasSubject.next(result); // assuming you have a Subject to manage LGAs
        },
        error: (err) => {
          console.error('Error fetching LGAs:', err);
        },
      });
  }
}
