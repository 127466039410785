import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EncryptDecryptService } from './encrypt-decrypt.service';
import { baseUrl } from '../classes/general';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { catchError, finalize, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocateService {
  public locateSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public OnAllGeograpicalLgaSubject: BehaviorSubject<any> =
    new BehaviorSubject<any>({});
  lgas: any[] = [];

  private _loading: any;
  constructor(
    private _http: HttpClient,
    private _encryptDecrypt: EncryptDecryptService
  ) {}

  getLga(id: any): Observable<any> {
    let encryptedPayload = {
      data: this._encryptDecrypt.encrypt(id),
    };

    return this._http.get<any>(`${baseUrl}/api/Location/GetLGAs/${id}`);
  }

  onAllGeographicalLga(id: any) {
    console.log('stateId>>>', id);
    // this.OnAllGeograpicalLgaSubject.next({});
    // this._loading.onOpenLoader();

    return this._http
      .get<any>(`${baseUrl}/api/Location/GetLGAs/${id}`)
      .pipe(
        finalize(() => {
          // this._loading.onCloseLoader();
        })
      )
      .subscribe({
        next: (res) => {
          // Decrypt and assign LGAs to the array
          const result = this._encryptDecrypt.decrypt(res.data);
          this.lgas = result; // Assign the decrypted LGAs to the component's lgas array
          this.OnAllGeograpicalLgaSubject.next(result);
        },
        error: (err) => {
          console.log('lgaError>>>', err);
        },
      });
  }
}
